export const environment = {
    production: false,
    urlApi: getUrlApi()
}

function getUrlApi() {
    const host = window.location.host
    switch (host) {
        case 'app-liberacred-front-prd-pre.azurewebsites.net': //PRE-PROD
            return "https://app-liberacred-back-prd-pre.azurewebsites.net/api"
        case 'app-liberacred-front-prd.azurewebsites.net': //PROD
            return "https://app-liberacred-back-prd.azurewebsites.net/api"
        case 'app-liberacred-front-qa.azurewebsites.net': // QA
            return "https://app-liberacred-back-qa.azurewebsites.net/api"
        case 'app-liberacred-front-uat.azurewebsites.net': // UAT
            return "https://app-liberacred-back-uat.azurewebsites.net/api"
        case 'app-liberacred-front-dev.azurewebsites.net': // DEV
            return "https://app-liberacred-back-dev.azurewebsites.net/api"
        case 'app-liberacred-front.azurewebsites.net': // INC
            return "https://app-liberacred-back.azurewebsites.net/api"            
        case 'beta.liberacred.com.br': // BETA-PROD EXTERNO
            return "https://apicore.liberacred.com.br/api"
        case 'www.liberacredyamaha.com.br': // PROD EXTERNO
            return "https://apicore.liberacred.com.br/api"
        default:
            return "https://localhost:5000/api"
    }
}
