import { ValidatorFn, AbstractControl } from "@angular/forms";

export function customEmailValidator(profile: number | string | null): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.value as string;

    if (profile === 5 && !email.indexOf('@')) {
      return { invalidEmailFormat: true };
    }

    return null;
  };
}
